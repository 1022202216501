import React, { Component } from "react"
import Layout from "../../components/layout"
import Clutch from "../../components/clutch"


import JIVA_logo from "../../images/jiva-ag/Jiva_logo.png"
import JIVA_img from "../../images/jiva-ag/Jiva-grp.png"
import JIVA_stat from "../../images/jiva-ag/Jiva_stats.jpg"


import Scroll_top_img from "../../images/scroll-arrow.png"
import {Helmet} from "react-helmet"

import { Link } from "gatsby";
import Calltoaction from "../../components/Call-to-action"

export default class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: ''
        }
    }

    componentDidMount() {
        this.setState({
            url: document.location.href
        });
    }
    render() {
        return (
            <Layout>
                <Helmet>
                    <title>Case Study For Organic Content Marketing Growth - Jiva.ag</title>
                    <meta name="description"
                        content="Empowering Jiva's mission: CueForGood' strategy led to a 354% boost in organic traffic, enhancing Jiva's digital footprint and aiding farmers worldwide." />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <link rel="canonical" href={this.state.url} />

                    <meta property="og:site_name" content="CueForGood" />
                    <meta property="fb:app_id" content="289086684439915" />
                    <meta property="og:url" content={this.state.url} />
                    <meta property="og:title"
                        content="Case Study For Organic Content Marketing Growth - Jiva.ag" />
                    <meta property="og:description"
                        content="Empowering Jiva's mission: CueForGood' strategy led to a 354% boost in organic traffic, enhancing Jiva's digital footprint and aiding farmers worldwide." />
                    <meta property="og:image" content="https://www.cueforgood.com/images/merging-blog.jpg" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:description"
                        content="Empowering Jiva's mission: CueForGood' strategy led to a 354% boost in organic traffic, enhancing Jiva's digital footprint and aiding farmers worldwide." />
                    <meta name="twitter:title"
                        content="Case Study For Organic Content Marketing Growth - Jiva.ag" />
                    <meta name="twitter:site" content="@CueForGood" />
                    <meta name="twitter:image" content="https://www.cueforgood.com/images/merging-blog.jpg" />
                </Helmet>
               <div className="cb_expertiseInner">
                  <div className="case_study_page high-roas-atg seed-ecommerce-atg jiva_ag">

                     <div className="case-wrap  ">
                           <div className="ww-sec-one ">
                              <div className="container">
                                 <h3>Case Study</h3>
                                 <h1><img src={JIVA_logo} alt="ATG logo" /></h1>
                                 <h2>CueForGood Transforming Blogging Results:</h2>
                                 <p className="perennialcycle_p">An Astounding 354.27% Organic Traffic Surge in Just 6 Months</p>
                              </div>
                           </div>
                     </div>

                     <div className="overall-roas ">
                        <div className="container">
                           <div className="out-roas-full">
                             <div className="overall-text">
                             <h3>About the Client</h3>
                              <p>
                              Headquartered in Indonesia, Jiva is on a remarkable mission to enhance the livelihoods of smallholder farmers on a global scale. 
                              </p>
                              <p>With determination to their mission and a profound understanding of farmers and their communities, Jiva develops innovative products and services that cater to the unique challenges faced by farmers in the field. </p>
                              <p>Jiva, through its app, provides farmers with no-cost advances for agricultural inputs, access to high-quality supplies through its e-commerce store, and personalized AI-powered advice to improve crop quality and yield.</p>
                              <p>Their impact has expanded to multiple countries, demonstrating their strong commitment to empowering farmers and driving positive change in the agricultural sector. </p>
                             </div>
                             <div className="overall-img">
                                  <img src={JIVA_img} alt="Jiva logo" />
                             </div>
                           </div>
                        </div>
                     </div>

                     <div className="about-client service-offer seeds-service">
                        <div className="container">
                           <div className="about-client-in">
                              <div className="about-client-left">
                                <h2 className="heading_main">Analysis</h2>
                                <p>As commendable as Jiva’s mission is, it faces the challenge of visibility in the online landscape. Jiva wanted to improve its online presence and rankings for specific agricultural terms. </p>
                                <p>Additionally, the client aimed to strengthen its brand recognition for terms like "Jiva" and "Jiva Indonesia."</p>
                                <p>To achieve this, the client has been publishing blogs both on Medium.com and their website -Jiva.ag. The blogs posted on Jiva.ag were redirected to Medium blogs.</p>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="Challenges ">
                        <div className="container">
                           <div className="process-inn">
                              <h2 className="heading_main">Our Process</h2>
                              <p>CueForGood performed an analysis of the client's website. Being a small website with only 5-7 main pages about the company, we informed the client about how they need to focus on content to reach their goals. We created a content strategy and identified that their blogs published on Medium.com were generating little to no traffic to their website. </p>
                              <p>Recognizing the need for a more effective approach, we recommended and implemented the following steps as part of our process:</p>
                              <div className="out-Challenges">
                              <div className="inn-Challenges">
                                 <h2 className="heading_count">1</h2>
                                 <p className="p-bold"><strong>Discontinuation of Redirection:</strong></p>
                                 <p>We advised the client to discontinue redirecting blogs from their website, Jiva.ag, to Medium.com. This change aimed to encourage readers to visit the client's website directly for a more engaging experience.</p>
                              </div>
                              <div className="inn-Challenges">
                                 <h2 className="heading_count">2</h2>
                                 <p className="p-bold"><strong>Mentioning Dual Publication:</strong></p>
                                 <p>Instead of solely publishing blogs on Medium, we recommended a dual publication strategy. Jiva continued to post blogs on their website and included a mention at the end of each blog, informing readers that the content was also available on Medium.com. With this approach, we aim to provide readers with flexibility in choosing their preferred platform.</p>
                                 </div>
                              <div className="inn-Challenges">
                                 <h2 className="heading_count">3</h2>
                                 <p className="p-bold"><strong>Canonical Links:</strong></p>
                                 <p>For blogs that had already been posted on Medium, we changed the canonical links in Medium and pointed them to the respective Jiva posts. This ensured that search engines considered the blogs on Jiva as canon articles and displayed them on search results. In addition, blogs on Medium would enjoy engagement from the Medium’s platform. This step was crucial in building the client's authority and ensuring that search engine traffic was directed to their website.</p>
                             </div>
                              <div className="inn-Challenges">
                                 <h2 className="heading_count">4</h2>
                                 <p className="p-bold"><strong>Crafting Content Briefs</strong></p>
                                 <p>We also crafted content briefs for the client by thoroughly researching the topics for additional blogs related to their target keywords. These content briefs served as guidelines for their writers to create additional content overall.</p>
                              </div>
                              </div>
                           </div>

                           <div className="green-bg">
                              <p>
                              Ready to test your blog’s potential with our proven strategies?  Get in touch today at - <a href="mailto:letstalk@cueforgood.com" id="first-focus-element" className="fv-w focus-visible">letstalk@cueforgood.com</a>
                              </p>
                           </div>
                           <div className="process-inn result-process">
                              <h2 className="heading_main">Results</h2>
                              <p className="result-p">Our strategic shift in content publication proved successful. After a few months, the website saw an increase in organic traffic, with the client’s overall visibility increasing for the main keywords and related topics. </p>
                              <div className="out-Challenges">
                              <div className="inn-Challenges">
                                 <h2 className="heading_count">1</h2>
                                 <p className="p-bold"><strong>Impressive Organic Traffic Growth</strong></p>
                                 <p>Following successful technical checks and content briefs, the client achieved a remarkable increase in organic traffic through the blogs. From April 1, 2023, to September 30, 2023, organic traffic has surged by an astounding 354.27% compared to the previous six months. This exponential growth demonstrates the effectiveness of our content and optimization efforts.</p>
                              </div>
                                 <div className="inn-Challenges">
                                 <h2 className="heading_count">2</h2>
                                 <p className="p-bold"><strong>Increased Website Traffic</strong></p>
                                 <p>By discontinuing the redirection of blogs and actively promoting dual publication, the client experienced a significant increase in traffic generated from search engines.</p>
                                 </div>
                                 <div className="inn-Challenges">
                                 <h2 className="heading_count">3</h2>
                                 <p className="p-bold"><strong>Increased Organic Visibility</strong></p>
                                 <p>The overall work done on blogs resulted in Jiva now showing up in search results for several target keywords. The site didn’t appear in the top 100 for any of the target keywords except the branded keywords before CueForGood started working on it. </p>
                                 </div>
                              </div>
                              <div className="pr-img">
                                  <img src={JIVA_stat} alt="Jiva stat" />
                              </div>


                           </div>
                        </div>
                     </div>
                    

               

                      <div className="seeds-rating ">
                        <div className="container">
                           <div className="seeds-bottom-review">
                            <p className="ct-top">Optimize your blog's performance and boost your organic traffic. Let's discuss how our strategies can work for your brand.</p>
                            <p>Contact us today at - <a href="mailto:letstalk@cueforgood.com" className="fv-w focus-visible">letstalk@cueforgood.com</a></p>
                           </div>
                        
                        </div>
                     </div>
                


                    <div id="scroll-to" className="case-scroll">
                       <a href="#" title="Scroll To Top" className="focus-visible"><img src={Scroll_top_img} alt="Scroll To Top" /></a>
                    </div>
                  </div>
               </div>

                <Clutch />

            </Layout>
        )
    }
}